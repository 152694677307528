<template>
  <div class="box">
    <!-- 用户未登陆 -->
    <div class="cellItem" v-if="!login">
      <div class="cell" v-if="!isYuu">
        <div>
          <span class="cellName">{{ huiYuanZhangHu }}</span>
        </div>
        <div class="login" @click="changeLogin">
          {{ lanType == 1 ? "登入" : "Login" }}
        </div>
      </div>
    </div>
    <div class="cellItem">
      <div v-if="login">
        <div class="cell" @click="changePage('personInfo')">
          <div>
            <img class="icon" src="@/assets/icon/cell6.png" alt="" />
            <span class="cellName">{{ new_WD_geRenZiLiao }}</span>
          </div>
          <div class="rightIcon"></div>
        </div>
        <div class="cell" @click="changePage('orderList', 3)">
          <div>
            <img class="icon" src="@/assets/icon/cell4.png" alt="" />
            <span class="cellName">{{ woDeDingDan }}</span>
          </div>
          <div class="rightIcon"></div>
        </div>
        <div class="cell" @click="changePage('vipcode')">
          <div>
            <img class="icon" src="@/assets/icon/code2.png" alt="" />
            <span class="cellName">{{ new_WD_huiYuanMa }}</span>
          </div>
          <div class="rightIcon"></div>
        </div>
        <div class="cell" @click="changePage('myScore/1')">
          <div>
            <img class="icon" src="@/assets/icon/cell3.png" alt="" />
            <span class="cellName">{{ New_WD_jiFenDuiHan }}</span>
          </div>
          <div class="rightIcon"></div>
        </div>
        <div class="cell" @click="changePage('place/1')">
          <div>
            <img class="icon" src="@/assets/icon/cell5.png" alt="" />
            <span class="cellName">{{ new_WD_guanLiDiZhi }}</span>
          </div>
          <div class="rightIcon"></div>
        </div>
        <div class="cell active" @click="changePage('CreditCard/1')">
          <div>
            <img class="icon" src="@/assets/icon/cell1.png" alt="" />
            <span class="cellName">{{ myParment }}</span>
          </div>
          <div class="rightIcon"></div>
        </div>
        <div class="cell" @click="jumpPage()">
          <div>
            <img class="icon" src="@/assets/icon/shop2.png" alt="" />
            <span class="cellName">{{ new_WD_fenDianWeiZhi }}</span>
          </div>
          <div class="rightIcon"></div>
        </div>
      </div>
      <!-- 一直都在 -->
      <div class="cell" @click="changePage('agreement')">
        <div>
          <img class="icon" src="@/assets/icon/cell7.png" alt="" />
          <span class="cellName">{{ tiaoKuanXieyi }}</span>
        </div>
        <div class="rightIcon"></div>
      </div>
    </div>
    <div class="cellItem">
      <div class="cell">
        <div>
          <img class="icon" src="@/assets/icon/cell8.png" alt="" />
          <span class="cellName">{{ yuYan }}</span>
        </div>
        <div class="rightBox">
          <div class="selectBox language">
            <span
              @click="changeLanguage(true, 1)"
              :class="{ active: language == 1 }"
              >中文</span
            >
            <span
              @click="changeLanguage(false, 2)"
              :class="{ active: language != 1 }"
              >EN</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="cell">
        <div>
          <img class="icon" src="@/assets/icon/cell5.png" alt="" />
          <span class="cellName">{{ diQu }}</span>
        </div>
        <div class="rightBox">
          <div class="selectBox place">
            <span
              @click="changePlace(true, 0)"
              :class="{ active: place == 0 }"
              >{{ HongKong }}</span
            >
            <span
              @click="changePlace(false, 1)"
              :class="{ active: place != 0 }"
              >{{ macau }}</span
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import language from "../../assets/js/language";

export default {
  name: "Cell",
  data() {
    return {
      lanType: this.$store.state.language,
      // 靜態文字
      woDeDingDan: "",
      new_WD_guanLiDiZhi: "",
      New_WD_jiFenDuiHan: "",
      new_WD_geRenZiLiao: "",
      new_WD_fenDianWeiZhi: "",
      tiaoKuanXieyi: "",
      yuYan: "",
      diQu: "",
      HongKong: "",
      macau: "",
      huiYuanZhangHu: "",
      myParment: "",
      new_WD_huiYuanMa: "",
    };
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    changeLogin() {
      window.dataLayer.push({
        event: "login",
      });
      this.$router.push("/login");
    },
    changeLanguage(type, lan) {
      window.dataLayer.push({
        event: "language_switch",
        language: lan == 1 ? "Chinese" : "E",
      });
      this.$store.commit("setLanguage", lan);
      this.language = type;
      this.$emit("setLanguage");
      this.setLanguage();
    },
    changePlace(type, area) {
      window.dataLayer.push({
        event: "country",
      });
      this.place = type;
      this.$store.commit("saveArea", area);
    },
    changePage(path, index) {
      if (path == "agreement") {
        window.dataLayer.push({
          event: "terms_condition",
        });
      }
      this.$router.push("/" + path);
      if (index) {
        this.$store.commit("changeTabPage", index);
      }
    },
    jumpPage() {
      this.$router.push({
        path: "/selectShop",
        query: {
          type: 3,
          pageName: "mine",
        },
      });
    },
    // 設置語言
    setLanguage() {
      this.lanType = this.$store.state.language;
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.woDeDingDan = language.woDeDingDan[type];
      this.new_WD_guanLiDiZhi = language.new_WD_guanLiDiZhi[type];

      this.New_WD_jiFenDuiHan = language.New_WD_jiFenDuiHan[type];
      this.new_WD_geRenZiLiao = language.new_WD_geRenZiLiao[type];
      this.tiaoKuanXieyi = language.tiaoKuanXieyi[type];
      this.yuYan = language.yuYan[type];
      this.diQu = language.diQu[type];
      this.HongKong = language.HongKong[type];
      this.macau = language.macau[type];
      this.huiYuanZhangHu = language.huiYuanZhangHu[type];
      this.myParment = language.myParment[type];
      this.new_WD_huiYuanMa = language.new_WD_huiYuanMa[type];
      this.new_WD_fenDianWeiZhi = language.new_WD_fenDianWeiZhi[type];
    },
  },
  computed: {
    ...mapState(["isYuu"]),
    place() {
      return this.$store.state.area;
    },
    language() {
      return this.$store.state.language;
    },
    login() {
      if (this.$store.state.userInfo) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.setLanguage();
  },
};
</script>
<style scoped>
.cellItem {
  margin: 0.16rem 0;
  border-radius: 0.08rem;
  overflow: hidden;
  background-color: #ffffff;
}
.cell {
  height: 0.48rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.16rem;
  box-sizing: border-box;
  border-bottom: 1px solid #cacbcc;
}
.cell.active {
  border-bottom: 1px solid #cacbcc !important;
}
.cell div:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icon {
  width: 0.2rem;
  height: 0.2rem;
  object-fit: cover;
}
.cellName {
  height: 0.54rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.54rem;
  color: #131314;
  margin-left: 0.08rem;
}
.rightIcon {
  width: 0.08rem;
  height: 0.14rem;
  background: url("../../assets/icon/rightr.png") no-repeat center;
  background-size: cover;
}
.cellItem>.cell:last-child {
  border: none;
}
.selectBox {
  min-width: 0.84rem;
  height: 0.34rem;
  background: #f7f9fa;
  border-radius: 0.17rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.34rem;
  color: #b1b1b3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectBox span {
  width: 50%;
  height: 100%;
  border-radius: 0.17rem;
  text-align: center;
  line-height: 0.34rem;
}
.language span.active {
  color: #ffffff;
  background-color: #e4022b;
}
.place span.active {
  color: #ffffff;
  background-color: #e4022b;
}
.login {
  width: 0.6rem;
  height: 0.28rem;
  background-color: #e4022b;
  border-radius: 0.16rem;
  font-size: 0.14rem;
  line-height: 0.28rem;
  text-align: center;
  color: #ffffff;
  /* background: url("../../assets/icon/loginBtn.png") no-repeat center;
  background-size: cover; */
}
</style>