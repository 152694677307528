<template>
  <div class="mine">
    <div class="topbar">
      <div class="title">{{ woDeZhangHu }}</div>
      <div>
        <!-- <img
          @click="changePage('information')"
          src="@/assets/icon/message.png"
          alt=""
        /> -->
      </div>
    </div>
    <!-- 会员卡 -->
    <div class="vipCard" v-if="login">
      <p class="levelBox">
        <span></span>
      </p>
      <p class="name">{{ userInfo.firstName }}</p>
      <p class="vipCodeNum">{{ userId }}</p>
      <p class="time">
        {{ jiFenDaoQi }} :
        {{ userInfo.pointExpiryDate ? userInfo.pointExpiryDate : "" }}
      </p>
      <p class="vip" ref="code" @click="changePage('vipcode')"></p>
    </div>
    <!-- cell列表 -->
    <div class="cellList">
      <div class="score" v-if="login">
        <!-- @click="changePage('score/1')" -->
        <div class="scoreNum" @click="changePage('myScore/1')">
          <i></i>
          <p>{{ userInfo.point }}</p>
          <p>{{ jiFen }}</p>
        </div>
        <!-- <i></i> -->
        <div class="coupon" @click="changePage('coupon/1', 2)">
          <i></i>
          <p>{{ coupon }}</p>
        </div>
      </div>
      <div class="cellBox" :class="{ active: userInfo.receiveNews }">
        <cell @setLanguage="setLanguage"></cell>
      </div>
    </div>
    <div class="notice" v-if="$store.state.userInfo && !userInfo.receiveNews">
      <div class="noticeLeft">
        <p>{{ new_WD_songShangYouHui }}</p>
        <p>{{ new_WD_qiYongTuiSOng }}</p>
        <p @click="showSetNotice">{{ new_WD_liJiKaiFang }}</p>
      </div>
      <div class="noticeRight">
        <img src="../assets/icon/a.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import Cell from "../components/mine/Cell.vue";
import AraleQRCode from "arale-qrcode";
import { postPaas } from "../assets/utils/request";
import language from "../assets/js/language";
export default {
  name: "Mine",
  data() {
    return {
      userInfo: {},
      lanType: this.$store.state.language,
      pointExpiryDate: null,
      // 靜態文字
      woDeZhangHu: "",
      huiYuanMa: "",
      jiFen: "",
      coupon: "",
      NA: "",
      jiFenDaoQi: "",
      woDeDingDan: "",
      woDeJiFen: "",
      woDeZiLiao: "",
      tiaoKuanXieyi: "",
      yuYan: "",
      diQu: "",
      HongKong: "",
      macau: "",
      new_WD_songShangYouHui: "",
      new_WD_qiYongTuiSOng: "",
      new_WD_liJiKaiFang: "",
      QDL:""
    };
  },
  methods: {
    createQRCode() {
      var code = new AraleQRCode({
        render: "png", // 生成的类型 'svg' or 'table'
        text: this.userInfo
          ? this.userInfo.userId + ""
          : "游客", // 需要生成二维码的链接
        size: 29, // 生成二维码大小
      });
      this.$refs.code.appendChild(code);
    },
    showSetNotice() {
      this.$store.commit("chageSetNotice", true);
    },
    // 获取用户信息
    async getUserInfo() {
      if (this.$store.state.userInfo) {
        let params = {
          actionName: "candao.member.userProfileGet",
          content: {},
        };
        let result = await postPaas("UserUnify", params);
        if (result.status == 1) {
          var D = result.data.pointExpiryDate;
          if (D) {
            D = D.split("T")[0];
            D = D.split("-").reverse().join("/");
          }
          result.data.pointExpiryDate = D;
          this.userInfo = result.data;
          this.createQRCode()
        }
      }
    },
    changePage(path, index) {
      if (path == "information") {
        window.dataLayer.push({
          event: "message_center",
        });
        return;
      }
      if (path == "vipcode" && !this.$store.state.userInfo) {
        this.$toast(this.QDL);
        return;
      }
      this.$router.push("/" + path);
      if (index) {
        this.$store.commit("changeTabPage", index);
      }
    },
    // 設置語言
    setLanguage() {
      this.lanType = this.$store.state.language;
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.woDeZhangHu = language.woDeZhangHu[type];
      this.huiYuanMa = language.huiYuanMa[type];
      this.coupon = language.coupon[type];
      this.NA = language.NA[type];
      this.jiFenDaoQi = language.jiFenDaoQi[type];

      this.jiFen = language.jiFen[type];
      this.woDeDingDan = language.woDeDingDan[type];
      this.woDeJiFen = language.woDeJiFen[type];
      this.woDeZiLiao = language.woDeZiLiao[type];
      this.tiaoKuanXieyi = language.tiaoKuanXieyi[type];
      this.yuYan = language.yuYan[type];
      this.diQu = language.diQu[type];
      this.HongKong = language.HongKong[type];
      this.macau = language.macau[type];
      this.new_WD_songShangYouHui = language.new_WD_songShangYouHui[type];
      this.new_WD_qiYongTuiSOng = language.new_WD_qiYongTuiSOng[type];
      this.new_WD_liJiKaiFang = language.new_WD_liJiKaiFang[type];
      this.QDL=this.$store.state.language==1?"請先登入":"Please login"
    },
  },
  computed: {
    login() {
      if (this.$store.state.userInfo) {
        return true;
      } else {
        return false;
      }
    },
    userId() {
      var id = this.$store.state.userInfo
        ? this.$store.state.userInfo.userId
        : " ";
      id = id.split("");
      var idStr = "";
      for (var i = 0; i < id.length; i++) {
        idStr += id[i];
        if (i != 0 && (i + 1) % 4 == 0) {
          idStr += " ";
        }
      }
      return idStr;
    },
  },
  created() {
    this.setLanguage();
    this.getUserInfo();
  },
  components: {
    Cell,
  },
};
</script>
<style scoped>
.mine {
  height: calc(100vh - 0.44rem);
  background-color: #f2f3f5;
  padding-bottom: 0.44rem;
  overflow-y: scroll;
}
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.44rem;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.title {
  font-size: 0.2rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.44rem;
  color: #030f14;
}
.topbar div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.topbar div:nth-child(2) > img {
  width: 0.16rem;
  height: 0.18rem;
  margin-left: 0.16rem;
}
.vipCode {
  width: 0.78rem;
  height: 0.29rem;
  background-color: #d9d9d9;
  border-radius: 0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.29rem;
  color: #1e1f21;
}
.vipCode img {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.04rem;
}
.vipCard {
  /* margin-top: 0.16rem ; */
  width: 3.43rem;
  height: 2rem;
  background: url("../assets/icon/card11.png") no-repeat center;
  background-size: 100%;
  margin-left: 0.16rem;
  position: relative;
  margin-bottom: 0.24rem;
}
.level {
  /* width: 0.72rem; */
  height: 0.23rem;
  border-radius: 0 0.04rem 0.04rem 0;
  background-color: #808080;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.23rem;
  color: #ffffff;
  padding: 0 0.05rem;
}
.levelBox {
  width: 3.43rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0.18rem;
  left: 0;
}
.levelBox i {
  width: 0.5rem;
  height: 0.5rem;
  background: url("../assets/icon/logom.png") no-repeat center;
  background-size: cover;
  margin-right: 0.16rem;
}
.name {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #03060d;
  text-align: left;
  position: absolute;
  top: 0.91rem;
  left: 0.24rem;
}
.vipCodeNum {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #03060d;
  text-align: left;
  position: absolute;
  top: 1.47rem;
  left: 0.24rem;
}
.time {
  height: 0.16rem;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #b1b1b3;
  text-align: left;
  position: absolute;
  top: 1.71rem;
  left: 0.24rem;
}
.cellList {
  width: 100%;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.69rem;
  /* background: #ffffff; */
  border-radius: 0.12rem;
}
.scoreNum,
.coupon {
  width: 48%;
  height: 0.69rem;
  font-size: 0.18rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.69rem;
  color: #231f20;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.12rem;
}
.scoreNum i {
  width: 0.2rem;
  height: 0.2rem;
  background: url("../assets/icon/jf.png") no-repeat center;
  background-size: cover;
}
.coupon i {
  width: 0.22rem;
  height: 0.16rem;
  background: url("../assets/icon/yhq.png") no-repeat center;
  background-size: cover;
}
.scoreNum p,
.coupon p {
  /* width: 100%; */
  text-align: center;
  margin-left: 0.06rem;
}
.scoreNum p:last-child {
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.17rem;
  color: #b5b6bd;
}
.score i {
  /* width: 1px;
  height: 0.22rem;
  background-color: #b5b6bd; */
}
.cellBox.active {
  padding-bottom: 0.4rem;
}
.vip {
  width: 0.57rem;
  height: 0.57rem;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 1.23rem;
  right: 0.16rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vip {
  /* background: url("../assets/icon/qrcode.png") no-repeat center #ffffff;
  background-size: 50%; */
}
.notice {
  background-color: rgba(228, 2, 43, 0.04);
  border-radius: 0.08rem;
  margin: 0 0.16rem;
  margin-bottom: 0.4rem;
  padding: 0.19rem 0.14rem 0.19rem 0.16rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.noticeLeft {
  width: 2.4rem;
}
.noticeLeft p {
  text-align: left;
}
.noticeLeft p + p {
  margin-bottom: 0.06rem;
}
.noticeLeft p:first-child {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #03060d;
}
.noticeLeft p:nth-child(2) {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #03060d;
}
.noticeLeft p:last-child {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #e4022b;
}
.noticeRight {
  width: 0.76rem;
  height: 0.76rem;
  display: flex;
}
.noticeRight img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>